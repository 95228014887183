import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// styles
import './App.css';

// components
import NavigationBar from './components/NavigationBar';

// pages
import MainPage from './pages/Main';
import PoliticsPage from './pages/Politics';
import ContactsPage from './pages/Contacts';
import CollectionPage from './pages/Collection';
import CatalogPage from './pages/Catalog';
import ProductPage from './pages/Product';
import CollectionsPage from './pages/Collections';
import AboutPage from './pages/About';
import InformationPage from './pages/Information';
import CartPage from './pages/Cart';
import CheckoutPage from './pages/Checkout';
import OrderTrackingPage from './pages/OrderTracking';
import { Product } from './types/Product';
import { CartProduct } from './types/CartProduct';
import GalleryPage from './pages/Gallery';
import GamePage from './pages/Game';

function App() {
  const [cart, setCart] = useState<Array<CartProduct>>([]);
  const [__render, __setRender] = useState<number>(0);

  useEffect(() => {
    let cartstr = getCookie('cart');
    if(cartstr != "" && cartstr != undefined) {
      setCart(JSON.parse(cartstr));
    }
  }, []);

  const onAddToCart = (p: CartProduct) => {
    let __cart = JSON.parse(JSON.stringify(cart));
    __cart.push(p);
    setCart(__cart);
    document.cookie = `cart=${JSON.stringify(__cart)};max-age=604800;path=/`;
    __setRender(1-__render);
  }

  const onRemoveFromCart = (p: CartProduct) => {
    let index = 0;
    let itemIndex = -1;
    for(const c of cart) {
      if(c.id == p.id && c.size == p.size) {
        itemIndex = index;
      }
      index += 1;
    }

    if(itemIndex != -1) {
      let __cart = JSON.parse(JSON.stringify(cart));
      __cart.splice(itemIndex, 1);
      setCart(__cart);
      document.cookie = `cart=${JSON.stringify(__cart)};max-age=604800;path=/`;
      __setRender(1-__render);
    }
  }

  return (
    <Router>
      <NavigationBar cart={cart}/>
      <Routes>
          <Route
            path=''
            element={<MainPage/>}
          />

          <Route
            path='politics'
            element={<PoliticsPage/>}
          />

          <Route
            path='information'
            element={<InformationPage/>}
          />

          <Route
            path='contacts'
            element={<ContactsPage/>}
          />

          <Route
            path='collection/:id'
            element={<CollectionPage/>}
          />

          <Route
            path='catalog'
            element={<CatalogPage/>}
          />

          <Route
            path='product/:id'
            element={<ProductPage cart={cart} onAdd={onAddToCart} onRemove={onRemoveFromCart}/>}
          />

          {/*<Route
            path='collections'
            element={<CollectionsPage/>}
          />*/}

          {/*<Route
            path='about'
            element={<AboutPage/>}
          />*/}

          <Route
            path='gallery'
            element={<GalleryPage/>}
          />

          <Route
            path='cart'
            element={<CartPage cart={cart} onUpdate={setCart} onRemove={onRemoveFromCart}/>}
          />

          <Route
            path='checkout/:orderid'
            element={<CheckoutPage/>}
          />

          <Route
            path='order-tracking/:orderid'
            element={<OrderTrackingPage/>}
          />

          <Route
            path='game'
            element={<GamePage/>}
          />
      </Routes>
    </Router>
  );
}

export default App;

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
