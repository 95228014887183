import React, { useEffect, useState } from "react";
import "../styles/Cart.scss";
import { Product } from "../types/Product";
import TextInput from "../components/TextInput";
import Selector from "../components/Selector";
import OrderService from "../services/OrderService";
import CatalogService from "../services/CatalogService";
import { CartProduct } from "../types/CartProduct";
import { Link } from "react-router-dom"; // @ts-ignore
import { Helmet } from 'react-helmet';

const orderService = new OrderService();
const catalogService = new CatalogService();

export default function CartPage({ cart, onUpdate, onRemove }: {
    cart: Array<CartProduct>,
    onUpdate: (c: Array<CartProduct>) => void,
    onRemove: (p: CartProduct) => void
}) {
    /*const [cart, setCart] = useState<Array<Product>>([
        {
            "_id": "0000000000004",
            "name": "Soviet T-Shirt",
            "brand": [],
            "availability": [
                {
                    "size": "M",
                    "amount": 8,
                    "prices": [
                        {
                            "currency": "RUB",
                            "value": 1000000,
                            "newValue": 0
                        }
                    ]
                }
            ],
            "condition": "new",
            "composition": [
                {
                    "alias": "ХЛОПОК",
                    "value": "100%"
                }
            ],
            "other_data": {},
            "description": "ФУТБОЛКА ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
            "categories": [
                {
                    "name": "sweatshirts",
                    "id": "0"
                }
            ],
            "created_at": "2023-08-10T13:02:16.208914Z",
            "images": [
                {
                    "id": "",
                    "min": "/media/0004_1.jpg",
                    "max": "/media/0004_1.jpg"
                },
                {
                    "id": "",
                    "min": "/media/0004_2.jpg",
                    "max": "/media/0004_2.jpg"
                },
                {
                    "id": "",
                    "min": "/media/0004_3.jpg",
                    "max": "/media/0004_3.jpg"
                }
            ]
        }
    ]);*/

    // order details
    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    const [deliveryMethod, setDeliveryMethod] = useState<string>("Самовывоз - бесплатно");
    const [shippingAddress, setShippingAddress] = useState<string>("");

    const [promocode, setPromocode] = useState<string>("");

    const [subtotal, setSubtotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const [cartItems, setCartItems] = useState<Array<Product>>([]);
    const [__render, __setRender] = useState<number>(0);

    // shipping address items
    const [city, setCity] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [building, setBuilding] = useState<string>("");
    const [flat, setFlat] = useState<string>("");
    const [zip, setZip] = useState<string>("");

    useEffect(() => {
        if(deliveryMethod == "Самовывоз - бесплатно") {
            setTotal(subtotal);
        } else if(deliveryMethod == "Доставка по Москве - 500 RUB") {
            setTotal(subtotal + 500);
        } else if(deliveryMethod == "Доставка по России - 1000 RUB") {
            setTotal(subtotal + 1000);
        }
    }, [deliveryMethod, subtotal]);

    useEffect(() => {
        if(cart.length != 0) {
            //let __cartItems: Array<Product> = [];
            catalogService.getCartItems({
                cart: cart,
                _callback: (c: Array<Product>) => {
                    setCartItems(c);
                    let __total = 0;
                    for(const p of c) {
                        __total += p.availability[0].prices[0].value;
                    }
                    setSubtotal(__total);

                    // update cart if needed
                    if(c.length != cart.length) {
                        alert("Некоторые товары из вашей корзины недоступны");
                        let __cart: Array<CartProduct> = [];
                        for(const ci of c) {
                            __cart.push({
                                id: ci._id,
                                size: ci.availability[0].size
                            });
                        }
                        onUpdate(__cart);
                        document.cookie = `cart=${JSON.stringify(__cart)};max-age=604800;path=/`;
                    }
                }
            });
        }
    }, [cart]);

    function tryCreateOrder() {
        if(name == "") {
            alert("Укажите имя");
            return;
        }

        if(surname == "") {
            alert("Укажите фамилию");
            return;
        }

        if(phone == "") {
            alert("Укажите номер телефона");
            return;
        }

        if(email == "") {
            alert("Укажите Email");
            return;
        }

        let deliveryType: "pickup" | "courier_moscow" | "courier" = "pickup";
        switch(deliveryMethod) {
            case "Самовывоз - бесплатно":
                deliveryType = "pickup";
                break;
            case "Доставка по Москве - 500 RUB":
                deliveryType = "courier_moscow";
                break;
            case "Доставка по России - 1000 RUB":
                deliveryType = "courier";
                break;
            default: 
                break;
        }

        // verify delivery address
        if(deliveryType != "pickup") {
            if(region == "" && deliveryType == "courier") {
                alert("Укажите регион доставки");
                return;
            }
            if(city == "" && deliveryType == "courier") {
                alert("Укажите город доставки");
                return;
            }
            if(street == "") {
                alert("Укажите улицу доставки");
                return;
            }
            if(building == "") {
                alert("Укажите адрес дома");
                return;
            }
            if(flat == "") {
                alert("Укажите номер квартиры");
                return;
            }
            if(zip.length < 5) {
                alert("Укажите почтовый индекс");
                return;
            }
        }

        let shippingAddressJSON = JSON.stringify({
            region: deliveryType=="courier_moscow"?"Москва":region,
            city: deliveryType=="courier_moscow"?"Москва":city,
            street: street,
            building: building,
            flat: flat,
            zip: zip
        });

        // verify cart
        catalogService.getCartItems({
            cart: cart,
            _callback: (c: Array<Product>) => {
                if(c.length != cartItems.length) {
                    alert("Некоторые товары из вашей корзины недоступны");
                    let __cart: Array<CartProduct> = [];
                    for(const ci of c) {
                        __cart.push({
                            id: ci._id,
                            size: ci.availability[0].size
                        });
                    }
                    onUpdate(__cart);
                    document.cookie = `cart=${JSON.stringify(__cart)};max-age=604800;path=/`;
                } else {
                    orderService.createOrder({
                        products: cartItems,
                        first_name: name,
                        last_name: surname,
                        email: email,
                        phone_number: phone,
                        delivery_type: deliveryType,
                        delivery_address: shippingAddressJSON,
                        _callback: (r) => {
                            window.location.replace(`/checkout/${r._id}`);
                        },
                        _onStockError: () => {
                            catalogService.getCartItems({
                                cart: cart,
                                _callback: (c: Array<Product>) => {
                                    let __cart: Array<CartProduct> = [];
                                    for(const ci of c) {
                                        __cart.push({
                                            id: ci._id,
                                            size: ci.availability[0].size
                                        });
                                    }
                                    onUpdate(__cart);
                                    document.cookie = `cart=${JSON.stringify(__cart)};max-age=604800;path=/`;
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    if(cart.length == 0) {
        return(
            <div className="Cart__root">
                <div className="wrapper" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',flexDirection:'column',gap:0}}>
                    <p style={{fontFamily:'monospace',fontSize:'30px'}}>Корзина пуста</p>
                    <Link to="/catalog" className="catalog_button">
                        <p>В каталог</p>
                    </Link>
                </div>
            </div>
        );
    }

    return(
        <>
        <Helmet>
            <script type="text/javascript">{`
            function purchasePickup() {
                _tmr.push({ type: 'reachGoal', id: 3384321, goal: 'Purchase'});
            }

            document.querySelector('#pay_button_pickup').onmousedown = purchasePickup;
            `}</script>
        </Helmet>
        <div className="Cart__root">
            <div className="wrapper">
                <div className="left">
                    <p className="title">Корзина</p>
                    <div className="cart_items_wrapper">
                        {cartItems.map((product, index) => {
                            return(
                                <CartItem
                                    product={product}
                                    key={index}
                                    onRemove={(p: CartProduct) => {
                                        onRemove(p);
                                    }}
                                />
                            );
                        })}
                    </div>
                    <p className="title total">Итого: {total} RUB</p>
                </div>

                <div className="right">
                    <div className="scroll_wrap">
                        <p className="title">Оформление</p>

                        <p className="section_title">Контактная информация</p>
                        <div className="inputs_wrapper">
                            <TextInput
                                title="Имя"
                                value={name}
                                onChangeText={setName}
                            />

                             <TextInput
                                title="Фамилия"
                                value={surname}
                                onChangeText={setSurname}
                            />

                            <TextInput
                                title="Телефон"
                                value={phone}
                                onChangeText={setPhone}
                            />

                            <TextInput
                                title="E-mail"
                                value={email}
                                onChangeText={setEmail}
                            />
                        </div>

                        <p className="section_title">Доставка</p>
                        <div className="inputs_wrapper">
                            <Selector
                                title="Способ доставки"
                                value={deliveryMethod}
                                onChange={setDeliveryMethod}
                                items={[
                                    "Самовывоз - бесплатно",
                                    "Доставка по Москве - 500 RUB",
                                    "Доставка по России - 1000 RUB",
                                ]}
                            />

                            {deliveryMethod != "Самовывоз - бесплатно" &&
                            <>
                                {/*<TextInput
                                    title="Полный адрес доставки"
                                    style={{marginTop: "24px"}}
                                    value={shippingAddress}
                                    onChangeText={setShippingAddress}
                                />*/}

                                <TextInput
                                    title="Регион"
                                    style={{marginTop: "24px"}}
                                    value={deliveryMethod != "Доставка по Москве - 500 RUB" ? region : "Москва"}
                                    onChangeText={setRegion}
                                    disabled={deliveryMethod == "Доставка по Москве - 500 RUB"}
                                />

                                <TextInput
                                    title="Город"
                                    value={deliveryMethod != "Доставка по Москве - 500 RUB" ? city : "Москва"}
                                    onChangeText={setCity}
                                    disabled={deliveryMethod == "Доставка по Москве - 500 RUB"}
                                />

                                <TextInput
                                    title="Улица"
                                    value={street}
                                    onChangeText={setStreet}
                                />

                                <div style={{
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '48px'
                                }}>
                                    <TextInput
                                        title="Номер дома"
                                        value={building}
                                        onChangeText={setBuilding}
                                    />
                                    <TextInput
                                        title="Квартира"
                                        value={flat}
                                        onChangeText={setFlat}
                                    />
                                </div>

                                <TextInput
                                    title="Индекс"
                                    value={zip}
                                    onChangeText={setZip}
                                />
                            </>
                            }

                            {deliveryMethod == "Самовывоз - бесплатно" &&
                            <p className="pickup_info">Самовывоз производится по адресу: <a href="https://yandex.ru/maps/-/C2Hxfma" target='_blank'>Нижний Сусальный пер., 5с17 (SPIN4SPIN)</a></p>
                            }
                        </div>

                        <div 
                            className="pay_button"
                            id="pay_button_pickup"
                            onClick={()=>{
                                tryCreateOrder();
                            }}
                            style={deliveryMethod=="Самовывоз - бесплатно"?{}:{display:'none'}}
                        >
                            <p>ОФОРМИТЬ ЗАКАЗ</p>
                        </div>

                        <div 
                            className="pay_button"
                            id="pay_button"
                            onClick={()=>{
                                tryCreateOrder();
                            }}
                            style={deliveryMethod!="Самовывоз - бесплатно"?{}:{display:'none'}}
                        >
                            <p>ОФОРМИТЬ ЗАКАЗ</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

function CartItem({ product, onRemove }: { product: Product, onRemove: (p: CartProduct) => void }) {
    return(
        <div className="CartItem__root">
            <img src={product.images[0].min} />
            <div className="desc_container">
                <p className="name">{product.name}</p>
                <p className="size">Размер {product.availability[0].size}</p>
                <p className="price">{product.availability[0].prices[0].value} {product.availability[0].prices[0].currency}</p>
            </div>
            <div className="actions" onClick={() => onRemove({
                id: product._id,
                size: product.availability[0].size
            })}>
                <p>X</p>
            </div>
        </div>
    );
}
